var session = require("modules/session");

	
module.exports = function( el ) {

 	    init();
 
		function init(){
			
      session.init();
    }

		
};
  