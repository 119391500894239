/**
*	@desc module control for Product Items
*/

module.exports = function( el ) {
	

    
	init();
  
	function init(){
    
    //var myLazyLoad = new LazyLoad();
    
    
	}
  


	
}; 
  