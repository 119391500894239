

var session = module.exports = {


  init: function(){
	  console.log('%c [session.init]', 'color:blue')
  },
  
  setSession: function(){
    
  },
  
  getSession: function(){
    
  }

  
  

	
};
